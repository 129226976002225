

import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./FirebaseConfig.js";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, View, ScrollView, Text, Icon, Image } from "@aws-amplify/ui-react";
import { AmplifyProvider } from "@aws-amplify/ui-react";

import SavedFilterConditionRecord from "./ui-components/SavedFilterConditionRecord";
import SavedFilterList from "./ui-components/SavedFilterList";
import DepartmentSelectedRecord from "./ui-components/DepartmentSelectedRecord";
import DepartmentSelectComponent from "./ui-components/DepartmentSelectComponent";

import { fireStore, getFunction } from './FirebaseConfig';
import { collection, getDocs, getDoc, doc, addDoc, setDoc, updateDoc, query, where, Timestamp } from 'firebase/firestore';

import { sysout } from './global.js'

import '@aws-amplify/ui-react/styles.css';
import './reset.css'
import './reset2.css'
import './destyle.css'

import './DropdownListComponent.css'

export default function DropdownListComponent(props) {
    //sysout(props.items);
    
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const setIsOpen_ = useCallback((v) => {
        sysout("setIsOpen(" + v + ")");
        setIsOpen(v);
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    }, []);

    const handleOutsideClick = (e) => {
        sysout("handleOutsideClick()");
        sysout(e);
        sysout(dropdownRef.current);
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen_(false);
        }
    };
    
    return (
        <>
            <Flex
            style={{"zIndex": 10}}
            ref={dropdownRef}
            onClick={() => setIsOpen_(!isOpen && !props.isDisabled)}
            gap="0"
            direction="row"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            border="1px SOLID rgba(0,0,0,0.5)"
            >
                <Flex
                gap="0"
                direction="column"
                width="fit-content"
                minWidth="100px"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 10px 0px 10px"
                >
                    <Text
                        style={{"pointerEvents": "none"}}
                        fontFamily="Inter"
                        fontSize="20px"
                        fontWeight="400"
                        color={props.frontMessageColor}
                        textTransform="capitalize"
                        lineHeight="23.4375px"
                        textAlign="left"
                        display="flex"
                        direction="column"
                        justifyContent="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children={props.frontMessage}
                    ></Text>
                </Flex>
                <Flex
                gap="0"
                direction="column"
                width="fit-content"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                >
                    <Image
                        width="36px"
                        height="36px"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        src="images/arrow_dropdown.png"
                    ></Image>
                </Flex>
                {isOpen &&
                    <Flex
                        className="dropdownsheet"
                        gap="0"
                        position="absolute"
                        top="100%"
                        left="0"
                        width="fit-content"
                        maxHeight="50vh"
                        padding="2px 0px 0px 0px"
                        margin="auto"
                    >
                        <ScrollView
                        display="flex"
                        gap="0px"
                        direction="column"
                        minWidth="100px"
                        shrink="0"
                        position="relative"
                        backgroundColor="rgba(255,255,255,1)"
                        border="1px SOLID rgba(0,0,0,0.54)"
                        padding="0px 0px 0px 0px"
                        >
                            {props.items.map((name, index) => {
                                if (name === null){
                                    return (
                                        <hr
                                            key={index}
                                            style={{
                                                color: "rgba(0,0,0,0.3)",
                                                height: 1,
                                                margin: "10px 10px 10px 10px"
                                            }}
                                        />
                                    );
                                }
                                else {
                                    return (
                                        <Flex
                                            key={index}
                                            itemname={name}
                                            onClick={props.whenClicked}
                                            className="dropdownentries"
                                            position="relative"
                                            padding="5px 10px 5px 10px"
                                        >
                                            <Text
                                                style={{"pointerEvents": "none"}}
                                                fontFamily="Inter"
                                                fontSize="20px"
                                                fontWeight="400"
                                                color="rgba(0,0,0,1)"
                                                textTransform="capitalize"
                                                lineHeight="23.4375px"
                                                textAlign="left"
                                                display="flex"
                                                direction="column"
                                                justifyContent="flex-start"
                                                shrink="0"
                                                position="relative"
                                                padding="0px 0px 0px 0px"
                                                whiteSpace="pre-wrap"
                                                children={name}
                                            ></Text>
                                        </Flex>
                                    );
                                }
                            })}
                        </ScrollView>
                    </Flex>
                }
            </Flex>
      </>
    );
  }
  
