// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAL4PFzhiN4dHNv_OxjZlmn4yCrdrDpgvw",
  authDomain: "calla.valuesccg.net",
  databaseURL: "https://bouquet-355502-default-rtdb.firebaseio.com",
  projectId: "bouquet-355502",
  storageBucket: "bouquet-355502.appspot.com",
  messagingSenderId: "237986654776",
  appId: "1:237986654776:web:5f9e8706d693665f4654cd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const fireStore = getFirestore(app);

const functions = getFunctions(app);
functions.region = "asia-northeast1"
export const getFunction = (functionName) => {

  return httpsCallable(functions, functionName);
  /*
  const func1 = getFunction();
  func1({ text: messageText })
    .then((result) => {
      const data = result.data;
      const sanitizedMessage = data.text;
    });
  */
};
