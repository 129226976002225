import { useState, useEffect } from 'react';

const useIntersection = (ref) => {
  const [intersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });

    if (ref.current == null) return;

    observer.observe(ref.current);

    return () => {
    if (ref.current == null) return;
      observer.unobserve(ref.current);
    };
  });

  return intersecting;
};

export default useIntersection;