
export function getYM(dt) {
    var y = dt.getFullYear();
    var m = ('00' + (dt.getMonth()+1)).slice(-2);
    return (y + m);
}
export function getYMD(dt) {
    var y = dt.getFullYear();
    var m = ('00' + (dt.getMonth()+1)).slice(-2);
    var d = ('00' + dt.getDate()).slice(-2);
    return (y + m + d);
  }
  export function getYMDhyphen(dt) {
      var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      return (y + "-" + m + "-" + d);
  }

export function getYMDjapanese(dt) {
    var y = dt.getFullYear();
    var m = ('00' + (dt.getMonth()+1)).slice(-2);
    var d = ('00' + dt.getDate()).slice(-2);
    return (y + "年" + m + "月" + d + "日");
}

export function date_trunc(dt) {
  return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
}

export function startOfLastMonth(dt) {
  var result = date_trunc(dt);
  result.setDate(0);
  result.setDate(1);
  return result;
}

export function endOfLastMonth(dt) {
  var result = date_trunc(dt);
  result.setDate(0);
  return result;
}

export function startOfMonth(dt) {
  var result = date_trunc(dt);
  result.setDate(1);
  return result;
}

export function endOfMonth(dt) {
  var result = date_trunc(dt);
  result.setDate(1);
  result.setMonth(dt.getMonth() + 1);
  result.setDate(0);
  return result;
}

export function startOfNextMonth(dt) {
  var result = date_trunc(dt);
  result.setDate(1);
  result.setMonth(dt.getMonth() + 1);
  return result;
}

export function endOfNextMonth(dt) {
  var result = date_trunc(dt);
  result.setDate(1);
  result.setMonth(dt.getMonth() + 2);
  result.setDate(0);
  return result;
}

const datesOfMonth = {'yyyymm': []}
export function getDatesOfMonth(dt) {
  const yyyymm = getYM(dt);
  if (yyyymm in datesOfMonth) return datesOfMonth[yyyymm];
  const result = getDates(startOfMonth(dt), startOfNextMonth(dt))
  datesOfMonth[yyyymm] = result;
  return result;
}

export function getDates(start, end) {
  var result = [];
  for(var d = new Date(start); d < end; d.setDate(d.getDate()+1)) {
    result.push(new Date(d));
  }
  return result;
}

export function getWeekday(dt) {
  const weekDay = { 0: '日',  1: '月',  2: '火',  3: '水',  4: '木',  5: '金',  6: '土' };
  return weekDay[dt.getDay()]
}

export function getDateFromYMD(ymd) {
  return new Date(ymd.slice(0, 4), ymd.slice(4, 6)-1, ymd.slice(6, 8));
}

export const getTextFileAsync = async (filename) => {
  const url = new URL(import.meta.url);
  const path = url.searchParams.get("path");
  const response = await fetch(filename);
  const text = await response.text();

  return text;
}