/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./FirebaseConfig.js";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, View, ScrollView, Text, Icon, Image } from "@aws-amplify/ui-react";
import { AmplifyProvider } from "@aws-amplify/ui-react";

import NavBarLeft from "./ui-components/NavBarLeft";
import NavBarRight from "./ui-components/NavBarRight";
import AttendScheduleRecord from "./ui-components/AttendScheduleRecord";
import StatusBar from "./ui-components/StatusBar";
import ControlBarLeft from "./ui-components/ControlBarLeft";
import ControlBarRight from "./ui-components/ControlBarRight";
import AttendScheduleFilterModal from "./AttendScheduleFilterModal";
import AttendMembersModal from "./AttendMembersModal";
import HistoryModal from './HistoryModal';
import InfiniteScroll2 from "./InfiniteScroll2"
import AttendScheduleCondition from './AttendScheduleCondition'

import { fireStore, getFunction } from './FirebaseConfig';
import { collection, getDocs, getDoc, doc, addDoc, setDoc, updateDoc, query, where, Timestamp } from 'firebase/firestore';

import '@aws-amplify/ui-react/styles.css';
import './reset.css'
import './reset2.css'
import './destyle.css'

import { sysout } from './global.js'
import './Page.css'
import DropdownListComponent from './DropdownListComponent.js';
import { getYMD } from './util.js';

export default function Page(props) {
  const { overrides, ...rest } = props;
  const [showModal, setShowModal] = useState(0);
  
  const ref4scrollToday = useRef();
  const [currentYearLabel, setCurrentYearLabel] = useState("");
  const [showAttendReport4Leaders, setShowAttendReport4Leaders] = useState(false);

  const [appliedCondition, applyCondition] = useState(new AttendScheduleCondition([]));
  const [appliedConditionChanged, setAppliedConditionChanged] = useState(true);
  const [savedCondition, saveCondition] = useState();

  const [dropdownListLoaded, setDropdownListLoaded] = useState(false);
  const [depts, setDepts] = useState([]);
  const [dropdownMessage, setDropdownMessage] = useState("Loading....");
  const [dropdownColor, setDropdownColor] = useState("rgba(0, 0, 0, 0.5)");

  const [attendMembersModalData, setAttendMembersModalData] = useState({});
  
  const getUser = async (userName) => {
    const docRef = doc(fireStore, 'calla_m/members/records/' + userName);

    var result;
    await getDoc(docRef).then((docSnapshot) => {
      const data = docSnapshot.data();
      result = {
        "fullname": data.fullname
        ,"is_deleted": data.is_deleted
        ,"is_resigned": data.is_resigned
        ,"slack_id": data.slack_id
      }
    });
    return result;
  }

  const addUser = async (userName, fullname) => {
    const docRef = doc(fireStore, 'calla_m/members/records/' + userName);
    const userRef = await setDoc(docRef, {
      "fullname": fullname
      ,"is_deleted": false
      ,"is_resigned": false
      ,"slack_id": ""
    });
  }

  const checkUser = async (user) => {
    sysout("chekcUser()");
    const userName = user.email;
    const fullname = user.displayName;
    var muser;
    try{
      muser = await getUser(userName);
    }
    catch {
      await addUser(userName, fullname);
      muser = await getUser(userName);
    }
    if ( muser["is_deleted"] || muser["is_resigned"]) {
      logout();
    }
    sysout("chekcUser() OK!");
  };
  checkUser(props.user);

  const navigate = useNavigate();

  const logout = async () => {
    await signOut(auth);
    navigate("/login/");
  }

  const redirectToExternal = async (url) => {
    //window.location.href = url;
    window.open(url, '_blank')
  }
  const redirectToKOT = async () => { redirectToExternal("https://s2.kingtime.jp/independent/recorder/personal/") }

  const redirectToWorkPlanDashboard = async () => { redirectToExternal("https://datastudio.google.com/reporting/70a6645f-f9fc-4e79-b62b-5f093fb6cf17/page/p_swq17ymjzc") }

  const redirectToWorkPlanDashboard2 = async () => { redirectToExternal("https://datastudio.google.com/reporting/84b30237-dbfd-4616-84c9-a5b5e1a20378/page/TOx4C") }
  
  const onStart = async () => {
    let deptData = [];
    let dept2 = loadDepts(2);
    let dept3 = loadDepts(3);
    let dept4 = loadDepts(4);
    await Promise.all([dept2, dept3, dept4]).then((deptraw) => {
      deptData = deptData.concat(deptraw[0]);
      deptData.push(null);
      deptData = deptData.concat(deptraw[1]);
      deptData.push(null);
      deptData = deptData.concat(deptraw[2]);
    });
    setDepts(deptData);
    setDropdownMessage("メンバーを絞り込み....");
    setDropdownListLoaded(true);
    setAppliedConditionChanged(false);

    const request = {
      "operation": "getMyRole"
      ,"mode_int": 0
    }
    const func1 = getFunction('CallaGetDept');
    const response = await func1(request);
    const isLeader = response.data.result;
    sysout(isLeader);
    setShowAttendReport4Leaders(isLeader);
  }

  useEffect(() => {
    onStart();
  }, []);

  const hideModal = async () => {
    setShowModal(0);
  };

  const doShowModal = async (modalSpec) => {
    //modalSpec = 0 : hide modal
    //modalSpec = 1 : attendScheduleConditionModal
    //modalSpec = 2 : attendMembersModal
    //modalSpec = 3 : HistoryModal
    sysout("doShowModal() modalSpec=" + modalSpec);
    setShowModal(modalSpec);
  };

  const showAttendMemberModal = async (date, attendList) => {
    sysout("showAttendMemberModal() ymd=" + getYMD(date) + ", attendList=" + attendList);
    if (!dropdownListLoaded || appliedConditionChanged) return;
    setAttendMembersModalData({
      'date': date
      ,'attendList': attendList
    });
    doShowModal(2);
  };

  const loadDepts = useCallback(async (level) => {
    //sysout("loadDepts(" + level + ")");
    const request = {
      "operation": "getAllDepts"
      ,"level": level
    }
    const func1 = getFunction('CallaGetDept');
    const response = await func1(request);
    const result = Array.from(response.data, (rec) => rec['dept'+level]);
    
    //sysout("loadDepts(" + level + ") finished");
    return result;
  });

  useEffect(() => {
    sysout("appliedConditionChanged = " + appliedConditionChanged);
    if (appliedConditionChanged) return;
    let names = appliedCondition.depts;
    if (names.length === 0){
      setDropdownMessage("メンバーを絞り込み....");
      setDropdownColor("rgba(0, 0, 0, 0.5)");
    }
    else{
      setDropdownMessage(names.join('、'));
      setDropdownColor("rgba(0, 0, 0, 1)");
    }
  }, [appliedConditionChanged]);

  const onDeptListClicked = async(elem) => {
    setAppliedConditionChanged(true);
    const name = elem.target.innerText;
    //sysout("onDeptListClicked() name = " + name);
    //sysout(elem);
    applyCondition(new AttendScheduleCondition([name]));
    setDropdownMessage(name + " で絞込中...");
    setDropdownColor("rgba(0, 0, 0, 0.5)");
  }

  const resetCondition = async () => {
    setAppliedConditionChanged(true);
    applyCondition(new AttendScheduleCondition([]));
    setDropdownMessage("絞込解除中...");
    setDropdownColor("rgba(0, 0, 0, 0.5)");
  }

  const scrollToday = async () => {
    if(ref4scrollToday && ref4scrollToday.current) {
      ref4scrollToday.current.scrollIntoView()
    }
  }

  const doNothing = async () => {};

  return (
    <AmplifyProvider
      width="100%"
      height="100%"
      style={{width: "100%", height: "100%", position: "flex"}}
    >
    <Flex
      gap="0"
      width="100%"
      height="100%"
      direction="column"
      alignItems="center"
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
    >
      <Flex
        gap="0"
        direction="column"
        width="100%"
        height="100%"
        maxWidth="1366px"
        margin="auto"
        alignItems="center"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...rest}
        {...getOverrideProps(overrides, "Page")}
      >
        <Flex
          gap="0"
          direction="row"
          width="100%"
          height="auto"
          alignItems="center"
          position="relative"
          padding="24px 32px 24px 32px"
          backgroundColor="rgba(239,239,231,1)"
          {...rest}
          {...getOverrideProps(overrides, "Page")}
        >

          <Flex
            gap="0"
            direction="row"
            width="100%"
            height="100%"
            alignItems="left"
            position="relative"
            padding="0px 0px 0px 0px"
            {...rest}
            {...getOverrideProps(overrides, "Page")}
          >
            <Flex
              gap="20px"
              width="fit-content"
              height="44px"
              alignItems="center"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...rest}
              {...getOverrideProps(overrides, "NavBarLeft")}
            >
              <Flex
                gap="10px"
                width="fit-content"
                alignItems="center"
                shrink="0"
                height="44px"
                position="relative"
                padding="10px 10px 10px 10px"
                {...getOverrideProps(overrides, "Frame 350")}
              >
                <Flex
                  gap="2px"
                  width="fit-content"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  height="24px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Logo")}
                >
                  <Image
                    width="32px"
                    height="32px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src="images/calla.png"
                    {...getOverrideProps(overrides, "calla 1")}
                  ></Image>
                  <Text
                    fontFamily="Inter"
                    fontSize="20px"
                    fontWeight="700"
                    color="rgba(0,0,0,0.87)"
                    textTransform="capitalize"
                    lineHeight="23.4375px"
                    textAlign="left"
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="calla"
                    {...getOverrideProps(overrides, "calla")}
                  ></Text>
                </Flex>
                <Text
                  onClick={() => setShowModal(3)}
                  style={{
                    cursor: 'pointer'
                  }}
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(0,0,0,0.54)"
                  textTransform="capitalize"
                  lineHeight="23.4375px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Ver.0.51β"
                  {...getOverrideProps(overrides, "Ver.0.51\u03B2")}
                ></Text>
              </Flex>
              <Flex
                gap="20px"
                width="fit-content"
                alignItems="center"
                shrink="0"
                height="24px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 321")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(0,0,0,0.87)"
                  textTransform="capitalize"
                  lineHeight="23.4375px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="出社予定登録"
                  {...getOverrideProps(overrides, "PageTitle")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="row"
            width="100%"
            height="100%"
            justifyContent="flex-end"
            position="relative"
            padding="0px 0px 0px 0px"
            {...rest}
            {...getOverrideProps(overrides, "Page")}
          >

            <Flex
              gap="20px"
              width="fit-content"
              height="45px"
              justifyContent="flex-end"
              alignItems="center"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...rest}
              {...getOverrideProps(overrides, "NavBarRight")}
            >
              {showAttendReport4Leaders &&
                <Flex
                    onClick={redirectToWorkPlanDashboard2}
                    style={{
                      cursor: 'pointer'
                    }}
                    gap="0"
                    width="fit-content"
                    justifyContent="center"
                    alignItems="center"
                    shrink="0"
                    height="24px"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Frame 358")}
                  >
                    <Text
                      fontFamily="Inter"
                      fontSize="20px"
                      fontWeight="400"
                      color="rgba(0,0,0,0.87)"
                      textTransform="capitalize"
                      lineHeight="23.4375px"
                      textAlign="left"
                      display="flex"
                      direction="column"
                      justifyContent="flex-start"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="出社実績確認"
                      {...getOverrideProps(
                        overrides
                      )}
                    ></Text>
                  </Flex>
              }
              <Flex
                onClick={redirectToWorkPlanDashboard}
                style={{
                  cursor: 'pointer'
                }}
                gap="0"
                width="fit-content"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                height="24px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 358")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(0,0,0,0.87)"
                  textTransform="capitalize"
                  lineHeight="23.4375px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="出社状況サマリ"
                  {...getOverrideProps(
                    overrides
                  )}
                ></Text>
              </Flex>
              <Flex
                onClick={redirectToKOT}
                style={{
                  cursor: 'pointer'
                }}
                gap="0"
                width="fit-content"
                justifyContent="flex-end"
                alignItems="center"
                shrink="0"
                height="45px"
                overflow="hidden"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "KotButton")}
              >
                <Image
                  width="200px"
                  height="45px"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  src="kotlogo.png"
                  {...getOverrideProps(overrides, "kotlogo-isolation 1")}
                ></Image>
              </Flex>
              <Flex 
                onClick={logout}
                style={{
                  cursor: 'pointer'
                }}
                gap="0"
                width="fit-content"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                height="44px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "LogoutButton")}
              >
                <Image
                  width="44.2px"
                  height="44px"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  src="logout.png"
                  onClick={() => {
                    logoutOneOnClick();
                  }}
                  {...getOverrideProps(overrides, "logout 1")}
                ></Image>
              </Flex>
            </Flex>
          </Flex>

        </Flex>
        
        <Flex
          gap="0"
          direction="row"
          width="100%"
          height="auto"
          alignItems="center"
          position="relative"
          padding="10px 10px 10px 10px"
          backgroundColor="rgba(255,255,255,1)"
          {...rest}
          {...getOverrideProps(overrides, "Page")}
        >

          <Flex
            gap="0"
            direction="row"
            width="100%"
            height="100%"
            alignItems="left"
            position="relative"
            padding="0px 0px 0px 0px"
            {...rest}
            {...getOverrideProps(overrides, "Page")}
          >
            <Flex
              gap="0"
              width="100%"
              alignItems="center"
              position="relative"
              padding="0px 0px 0px 0px"
              {...rest}
              {...getOverrideProps(overrides, "ControlBarLeft")}
            >
              {false &&
                <Flex
                  onClick={doShowModal(1)}
                  gap="0"
                  width="fit-content"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  height="36px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "FilterButton")}
                >
                  <Image
                    width="36px"
                    height="36px"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    src="images/filter.png"
                    {...getOverrideProps(overrides, "filter1")}
                  ></Image>
                </Flex>
              }
              <Flex
                onClick={resetCondition}
                gap="0"
                width="fit-content"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                height="36px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "FilterResetButton")}
              >
                <Image
                  width="36px"
                  height="36px"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  src="images/filter_off.png"
                  {...getOverrideProps(overrides, "filter2")}
                ></Image>
              </Flex>
              <DropdownListComponent
                items={depts}
                whenClicked={onDeptListClicked}
                frontMessage={dropdownMessage}
                frontMessageColor={dropdownColor}
                isDisabled={!dropdownListLoaded || appliedConditionChanged}
                >
              </DropdownListComponent>
            </Flex>
          </Flex>

          <Flex
            gap="0"
            direction="row"
            width="100%"
            height="100%"
            justifyContent="flex-end"
            position="relative"
            padding="0px 0px 0px 0px"
            {...rest}
            {...getOverrideProps(overrides, "Page")}
          >
            {false &&
              <ControlBarRight
                  alignSelf="stretch"
                  overrides={
                    {
                      FilterResetButton: { 
                        onClick: logout
                        ,style: {
                          cursor: 'pointer'
                        }
                      }
                      ,JumpToTodayButton: {
                        onClick: scrollToday 
                      }
                      ,CurrentYearLabel: {
                        children: currentYearLabel
                      }
                    }
                  }
                />
            }
          </Flex>

        </Flex>

        <InfiniteScroll2
          condition={appliedCondition}
          setConditionChanged={setAppliedConditionChanged}
          showAttendMemberModal={showAttendMemberModal}
          todayref={ref4scrollToday}
          setcurrentyearlabel={setCurrentYearLabel}/>
        
        <Flex
          gap="10px"
          direction="column"
          width="100%"
          height="44px"
          alignItems="center"
          position="relative"
          padding="10px 10px 10px 10px"
          backgroundColor="rgba(62,55,46,0.95)"
        >
          <Flex
            gap="10px"
            direction="row"
            height="24px"
            alignItems="center"
            grow="1"
            basis="24px"
            alignSelf="stretch"
            objectFit="cover"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
            >
              <Icon
                width="20px"
                height="20px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
                paths={[
                  {
                    d: "M9 16L11 16L11 14L9 14L9 16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8L8 8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13L11 13C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="8.33%"
                bottom="8.33%"
                left="8.33%"
                right="8.33%"
              ></Icon>
            </View>
            <Flex
              gap="0"
              display="flex"
              direction="column"
              width="100%"
              grow="1"
              overflow="hidden"
              position="relative"
              justifyContent="flex-end"
              alignItems="center"
              padding="0px 0px 0px 0px"
            >
              <Text
                width="100%"
                className="instruction_text"
                fontFamily="Inter"
                children="「自宅」「オフィス」ボタンを押して出社予定を登録してね！ 画面上側のドロップダウンリストで部署を選択すると、出社メンバー表示を絞り込むことができます。 画面上端「出社一覧はこちら」をクリックすると、出社予定一覧がダッシュボードで見れます。"
              ></Text>
            </Flex>
          </Flex>
        </Flex>

        </Flex>
      </Flex>
      
      {showModal !== 0 ? (
          <Flex
            style={{"zIndex": 100}}
            onClick={hideModal}
            gap="0"
            width="100%"
            height="100%"
            direction="column"
            alignItems="center"
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(0,0,0,0.2)"
          >
          </Flex>
        ) : (
          <></>
        )}
    
        {showModal === 1 ? (
            <Flex
              style={{"zIndex": 100}}
              className='modal_window_parent'
              gap="0"
              direction="column"
              alignItems="center"
              position="absolute"
              top="50%"
              left="50%"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(255,255,255,1)"
            >
              <AttendScheduleFilterModal applycondition={applyCondition} savecondition={saveCondition} setshowmodal={setShowModal}></AttendScheduleFilterModal>
            </Flex>
          ) : (
            <></>
          )}
    
          {showModal === 2 ? (
              <Flex
                style={{"zIndex": 100}}
                className='modal_window_parent'
                gap="0"
                direction="column"
                alignItems="center"
                position="absolute"
                top="50%"
                left="50%"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(255,255,255,1)"
              >
                <AttendMembersModal appliedCondition={appliedCondition} setShowModal={setShowModal} data={attendMembersModalData}></AttendMembersModal>
              </Flex>
            ) : (
              <></>
            )}
    
            {showModal === 3 ? (
                <Flex
                  style={{"zIndex": 100}}
                  className='modal_window_parent'
                  gap="0"
                  direction="column"
                  alignItems="center"
                  position="absolute"
                  top="50%"
                  left="50%"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(255,255,255,1)"
                >
                  <HistoryModal setShowModal={setShowModal}></HistoryModal>
                </Flex>
              ) : (
                <></>
              )}

    </AmplifyProvider>
  );
}
