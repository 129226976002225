// app.tsx
import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import useIntersection from './intersection';
import AttendScheduleRecord from "./ui-components/AttendScheduleRecord";
import { Flex, View, ScrollView, Text } from "@aws-amplify/ui-react";
import { useBreakpointValue } from '@aws-amplify/ui-react'
import { produce } from "immer";

import { fireStore, getFunction } from './FirebaseConfig';
import { collection, getDocs, getDoc, doc, addDoc, updateDoc, query, where, Timestamp, documentId } from 'firebase/firestore';

import { getYM, getYMD, getYMDhyphen, getWeekday, date_trunc, getDateFromYMD
  , endOfMonth, endOfNextMonth, startOfMonth, startOfNextMonth
  , getDatesOfMonth, getDates, startOfLastMonth
} from './util'
import { sysout } from './global.js'
import { auth } from './FirebaseConfig'
import LoadingComponent from "./Loading";
import DateBarComponent from "./DateBarComponent";

import './InfiniteScroll2.css'

export default function InfiniteScroll2(props) {

  const filterCondition = props.condition;

  const scrollTodayRef = props.todayref;
  const [todayYmd, setTodayYmd] = useState(getYMD(new Date()));
  const setcurrentyearlabel = props.setcurrentyearlabel;

  const [dontchange, setDontchane] = useState();
  const [loaded, setLoaded] = useState(false);

  const [fetching, setFetching] = useState(true);
  const [items, setItems] = useState([]);
  const [lastItemWorkDate, setLastItemWorkDate] = useState(null);

  const [attendLists, setAttendLists] = useState({});
  const [weekdayClasses, setWeekdayClasses] = useState({});

  const refNext = useRef(null);
  const intersectionNext = useIntersection(refNext);
  const [intersectedNext, setIntersectedNext] = useState(true);
  const refPrev = useRef(null);
  const intersectionPrev = useIntersection(refPrev);
  const [intersectedPrev, setIntersectedPrev] = useState(true);

  const currentUserName = auth.currentUser.email;
  const currentUserDisplayName = auth.currentUser.displayName;
  const locationDocumentId = {};
  const buttonStat = {};

  sysout("go")
  const getData = async (yyyymm, start, end) => {
    sysout(start);
    sysout(end);
    const cref = collection(fireStore, 'calla', 'planWorkLocation', yyyymm, currentUserName, 't_location_plan');
    const q = query(cref, where("work_date", ">=", start), where("work_date", "<", end));

    var result;
    await getDocs(q).then((querySnapshot) => {
      result = new Map(querySnapshot.docs.map( (doc) => {
        const data = doc.data();
        const work_date = date_trunc(data.work_date.toDate());
        const ymd = Number(getYMD(work_date));
        return [ymd, {'id': doc.id, 'location_kbn': data.location_kbn, 'work_date': work_date}];
      }));
    });
    return result;
  }

  const writeData = async (ymd, work_location) => {
    const yyyymm = ymd.slice(0, 6);
    var documentId;
    if (ymd in locationDocumentId) {
      documentId = locationDocumentId[ymd];
      const docRef = doc(fireStore, 'calla/planWorkLocation/' + yyyymm + '/' + currentUserName + '/t_location_plan/' + documentId);
      const locRef = await updateDoc(docRef, {
        "location_kbn": work_location
      });
    }
    else {
      const cref = collection(fireStore, 'calla', 'planWorkLocation', yyyymm, currentUserName, 't_location_plan');
      const locRef = await addDoc(cref, {
        "location_kbn": work_location
        ,"work_date": Timestamp.fromDate(getDateFromYMD(ymd))
      });
      documentId = locRef.id;
      locationDocumentId[ymd] = documentId;
    }
  }

  const onLocationChanged = async (buttonHome, buttonOffice, stat, ymd, work_location) => {
    sysout("onLocationChanged", ymd, work_location);
    await writeData(ymd, work_location);
    stat *= -1;
    if (stat == 1) {
      buttonHome.className = 'button_info_default';
      buttonOffice.className = 'button_error_default';
    }
    if (stat == 2) {
      buttonHome.className = 'button_info_reversed';
      buttonOffice.className = 'button_error_default';
    }
    if (stat == 3) {
      buttonHome.className = 'button_info_default';
      buttonOffice.className = 'button_error_reversed';
    }
    buttonStat[ymd] = stat;
  }

  const onLocationDetermined  = function (elem) {
    const id = elem.target.id;
    const ymd = id.split('_')[1];
    var stat = buttonStat[ymd];
    sysout("stat=",stat);
    if (stat < 0) return;
    buttonStat[ymd] = -1;
    sysout("onLocationDetermined()", elem);
    const spec = id.split('_')[0];
    const buttonId = id.replace('_label', '');
    const textId = buttonId + "_label"
    const button = document.getElementById(buttonId);
    var button2;
    const text = document.getElementById(textId);
    sysout(id, buttonId);
    sysout(button.className);
    if (spec == 'buttonAtHome') {
      if (stat == 2) {
        stat = -1
      }
      else {
        stat = -2
      }
      const buttonId2 = buttonId.replace('Home', 'Office')
      button2 = document.getElementById(buttonId2);
      button.className = 'button_info_clicked';
      button2.className = 'button_error_disabled';
      buttonStat[ymd] = stat;
      onLocationChanged(button, button2, stat, ymd, -1 * stat - 1);
    }
    if (spec == 'buttonAtOffice') {
      if (stat == 3) {
        stat = -1
      }
      else {
        stat = -3
      }
      const buttonId2 = buttonId.replace('Office', 'Home')
      button2 = document.getElementById(buttonId2);
      button.className = 'button_error_clicked';
      button2.className = 'button_info_disabled';
      buttonStat[ymd] = stat;
      onLocationChanged(button2, button, stat, ymd, -1 * stat - 1);
    }
  }

  const setTodayElement = async() => {
    sysout("setTodayElement()");
    const newTodayYmd = getYMD(new Date());
    if (todayYmd != newTodayYmd) setTodayYmd(newTodayYmd);
  }

  /*
  const setStateDict = (oldState, setfunc, key, value) => {
    setfunc({
      ...oldState,
      [key]: value
    })
  }
  const setStateDictMulti = (oldState, setfunc, kv) => {
    let copy = JSON.parse(JSON.stringify(oldState));
    for (let key in kv) {
      copy[key] = kv[key]
    }
    setfunc(copy);
  }
  */
  const setStateDict = (oldState, setfunc, key, value) => {
    setfunc({
      ...oldState,
      [key]: value
    })
  }
  const setStateDictMulti = (oldState, setfunc, kv) => {
    sysout("setStateDictMulti()");
    const newState = produce(
      oldState,
      (st) => {
        for (let key in kv) {
          st[key] = kv[key]
        }
      }
    );
    //sysout(oldState);
    //sysout(newState);
    setfunc({ ... newState});
  }

  const createRecord = (work_date, documentId, location_kbn) => {
    const ymdToday = getYMD(new Date());
    const ymd = getYMD(work_date);
    if (documentId != 'undefined') locationDocumentId[ymd] = documentId;
    /*
    var refToday;
    if ( ymd == ymdToday ) {
      refToday = scrollTodayRef;
      class4 = "record_today"
    }
    */
    return {
      "ymd": ymd
      ,"date": work_date
      ,"documentId": documentId
      ,"location_kbn": location_kbn
      ,"weekdayClass": null 
      ,"attendList": null
    };
  }

  const checkRecord = (d, records) => {
    var newRecord = records.get(Number(getYMD(d)));
    if (newRecord == null){
      newRecord = {
        "id": "undefined"
        ,"work_date": d
        ,"location_kbn": 0
      }
    }
    return newRecord;
  }

  const addEntries = (entries) => {
    setLastItemWorkDate(entries[entries.length-1].work_date);
    const records = Array.from(entries, (rec) => createRecord(rec['work_date'], rec['id'], rec['location_kbn']));
    setItems(items.concat(records));
  }

  const getAttendList = async (start, end, members) => {
    sysout("getAttendList() ", start, end, members);

    const docRef1 = doc(fireStore, 'calla_free/attendList');
    const docSnap1 = await getDoc(docRef1);
    const data1 = docSnap1.data();
    const lastDocumentId = data1.lastDocumentId;
    const cref = collection(fireStore, 'calla_free/attendList/results/' + lastDocumentId + '/records')
    const q = query(cref, where("work_date", ">=", start), where("work_date", "<", end));

    var result;
    await getDocs(q).then((querySnapshot) => {
      result = new Map(querySnapshot.docs.map( (doc) => {
        const data = doc.data();
        const work_date = date_trunc(data.work_date.toDate());
        const ymd = Number(getYMD(work_date));
        return [ymd, {'id': doc.id, 'fullname_rare': data.fullname_rare, 'member_rare': data.member_rare}];
      }));
      
    });
    return result;
    /*
    //sysout("getHoliday()");
    const SQL = "select * from call.v_rare_member_at_office where work_date >= " + start + " and work_date <=" + end
    if (members != null ) SQL + ` and member_rare in [` + members.join(', ') + `]`;
    //sysout(SQL);
    const funcCallaBigQuery = getFunction("CallaBigQuery");
    //sysout("getHoliday() start function...")
    const queryResult = await funcCallaBigQuery({"query": SQL});
    //sysout("getHoliday() function finished");
    //sysout(queryResult);
    return Array.from(queryResult.data, (row) => ymd, {'id': doc.id, 'fullname_rare': data.fullname_rare, 'member_rare': data.member_rare})
    queryResult.data.forEach((row) => {
      const ymd = Number(getYMD(row.work_date));
      const date = new Date(ymd);
      const rec = getRecordElement(date);
      rec.className = rec.className + " record_sunday"
      return [ymd, {'id': doc.id, 'fullname_rare': data.fullname_rare, 'member_rare': data.member_rare}];
    });
    */
  }

  const updateStateList = (oldState, setfunc, operation) => {
    const newState = produce(
      oldState,
      (draft) => {
        return operation(draft);
      }
    );
    setfunc(newState);
  }

  const setHolidayAsync = async (start, end) => {
    //sysout("getHolidayAsync() start=" + start + ", end=" + end);
    const SQL = "select date from general.v_holiday_values where date >= '" + start + "' and date < '" + end + "' order by date asc;";
    //sysout(SQL);
    const funcCallaBigQuery = getFunction("CallaBigQuery");
    //sysout("getHoliday() start function...")
    const queryResult = await funcCallaBigQuery({"query": SQL});
    //sysout("getHoliday() function finished");
    //sysout(queryResult);
    queryResult.data.forEach((row) => {
      const date = new Date(row.date.value);
      const ymd = getYMD(date);
      let clsname = "record_sunday"
      if (getWeekday(date) == '土') clsname = "record_saturday"
      weekdayClasses[ymd] = clsname;
    });
    setWeekdayClasses({...weekdayClasses});
  };

  const setAttendListAsync = async (start, end, condition) => {
    sysout("setAttendListAsync()");
    const depts = condition.depts;
    const request = {
      "operation": "getAttendList"
      ,"start": getYMDhyphen(start)
      ,"end": getYMDhyphen(end)
      ,"depts": depts
    }
    const func1 = getFunction('CallaGetDept');
    const response = await func1(request);
    
    getDates(start, end).forEach((date) => {
      const ymd = getYMD(date);
      attendLists[ymd] = "";
    });
    response.data.forEach((row) => {
      const date = new Date(row.work_date.value);
      const ymd = getYMD(date);
      const namesBefore = row.names.filter(name => name === currentUserDisplayName);
      const namesAfter = row.names.filter(name => name != currentUserDisplayName);
      const names = namesBefore.concat(namesAfter);
      attendLists[ymd] = names.join('、');
    });
    //sysout(attendLists);
    setAttendLists({...attendLists});
  };

  useEffect(() => {
    //sysout("useEffect() weekdayClasses");
    //sysout(weekdayClasses);
    const newItems = items.map(item => {
      if (item['ymd'] in weekdayClasses){
        item['weekdayClass'] = weekdayClasses[item['ymd']];
        return item;
      }
      else{
        return item;
      }
    });
    setItems(newItems);
  }, [weekdayClasses]);

  useEffect(() => {
    //sysout("useEffect() weekdayClasses");
    //sysout(weekdayClasses);
    const newItems = items.map(item => {
      if (item['ymd'] in attendLists){
        item['attendList'] = attendLists[item['ymd']];
        return item;
      }
      else{
        return item;
      }
    });
    setItems(newItems);
  }, [attendLists]);

  const onFilterConditionChanged = useCallback(async () => {
    if (!loaded) return;
    setAttendLists({});
    var lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    await setAttendListAsync(startOfMonth(lastMonth), startOfNextMonth(lastItemWorkDate), filterCondition);
    props.setConditionChanged(false);
  });

  useEffect(() => {
    onFilterConditionChanged();
  }, [filterCondition]);

  const onStart = async () => {
    const now = new Date();
    const nextMonth = startOfNextMonth(now);
    const lastMonth = startOfLastMonth(now);
    const records1 = await getData(getYM(lastMonth), startOfMonth(lastMonth), startOfNextMonth(lastMonth));
    const records2 = await getData(getYM(now), startOfMonth(now), startOfNextMonth(now));
    const records3 = await getData(getYM(nextMonth), startOfMonth(nextMonth), startOfNextMonth(nextMonth));
    var entries = [];
    getDatesOfMonth(lastMonth).forEach((d) => {
      entries.push(checkRecord(d, records1));
    });
    getDatesOfMonth(now).forEach((d) => {
      entries.push(checkRecord(d, records2));
    });
    getDatesOfMonth(nextMonth).forEach((d) => {
      entries.push(checkRecord(d, records3));
    });
    addEntries(entries);
  
    setInterval( () => {
      setTodayElement();
    },1000 * 60 * 10);
    setHolidayAsync(getYMDhyphen(startOfMonth(lastMonth)), getYMDhyphen(startOfNextMonth(nextMonth)));
    setAttendListAsync(startOfMonth(lastMonth), startOfNextMonth(nextMonth), filterCondition);

    setFetching(false);
    setLoaded(true);
  }

  // フェッチ後自動スクロール
  useEffect(() => {
    setTodayElement();

    if(scrollTodayRef && scrollTodayRef.current) {
      scrollTodayRef.current.scrollIntoView()
    }
  }, [loaded]);

  useEffect(() => {
    // same as componentDidMount
    // https://qiita.com/nouka/items/69a74eebc43e7496007b
    onStart();
  }, [dontchange]);

  
  const onIntersectPrev = async () => {
    setFetching(true);
    
    // データ取得処理
    setTimeout(() => {
        setItems([...items, Array.from({ length: 2 }, () => <AttendScheduleRecord />)]);
      }, 500);
    
    setFetching(false);
  }
  
  const onIntersectNext = async () => {
    //sysout("onIntersectNext()");
    //sysout(lastItemWorkDate);
    if (lastItemWorkDate == null) return;

    setFetching(true);

    var start = new Date(lastItemWorkDate);
    start.setDate(start.getDate() + 1);
    //var end = new Date(start);
    //end.setDate(end.getDate() + 14);
    const end = startOfNextMonth(start);
    
    var entries = [];
    const records = await getData(getYM(start), start, end);
    getDates(start, end).forEach((d) => {
      entries.push(checkRecord(d, records));
    });
    
    /*
    if ( start.getMonth() != end.getMonth() ) {
      const records = await getData(getYM(end), start, end);
      getDates(start, end).forEach((d) => {
        entries.push(checkRecord(d, records));
      });
    }
    */
    //sysout(entries);
    addEntries(entries);
    setHolidayAsync(getYMDhyphen(start), getYMDhyphen(end));
    setAttendListAsync(startOfMonth(start), startOfNextMonth(end), filterCondition);
    
    setFetching(false);
  }

  useEffect(() => {
    setIntersectedNext(intersectionNext);
  }, [intersectionNext]);
  
  useEffect(() => {
    if(intersectedNext && !fetching) {
        onIntersectNext();
    }
  }, [intersectedNext,fetching]);
  
  useEffect(() => {
    setIntersectedPrev(intersectionPrev);
  }, [intersectionPrev]);
  
  useEffect(() => {
    if(intersectedPrev && !fetching) {
        onIntersectPrev();
    }
  }, [intersectedPrev,fetching]);

  const loadingText = (
    <Flex
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="center"
        position="relative"
        padding="0px 0px 0px 0px"
    >
      <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="400"
            color="rgba(0,0,0,0.4)"
            textTransform="capitalize"
            lineHeight="23.4375px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="loading..."
          ></Text>
    </Flex>
  );
  
  return (
    <>
    <ScrollView
      width="100%"
      height="100%"
      padding="10px 10px 10px 10px"
      style={{overflowX:"hidden", overflowY:"scroll"}}
    >
        <View 
          display="flex"
          direction="column"
          position="relative"
          gap="10px"
          padding="10px 10px 10px 10px"
          >

          {items.map((args) => (
            <div
              key={getYMD(args.date)}
              ref={todayYmd == getYMD(args.date) ? scrollTodayRef : null}
              className={todayYmd == getYMD(args.date) ? "record_today" : null}
            >
              <DateBarComponent
                date={args.date}
                location_kbn={args.location_kbn}
                documentId={args.documentId}
                weekdayClass={args.weekdayClass}
                attendList={args.attendList}
                showAttendMemberModal={props.showAttendMemberModal}
              />
            </div>
          ))}
      
          {!fetching ? (
          <div ref={refNext}>
              {loadingText}
          </div>
          ) : (
            <>{loadingText}</>
          )}
        </View>
    </ScrollView>
    </>
  )
}