
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./FirebaseConfig.js";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, View, ScrollView, Text, Icon, Image } from "@aws-amplify/ui-react";
import { AmplifyProvider } from "@aws-amplify/ui-react";

import SavedFilterConditionRecord from "./ui-components/SavedFilterConditionRecord";
import SavedFilterList from "./ui-components/SavedFilterList";
import DepartmentSelectedRecord from "./ui-components/DepartmentSelectedRecord";
import DepartmentSelectComponent from "./ui-components/DepartmentSelectComponent";
import ImplDepartmentSelect from './ImplDepartmentSelect';
import AttendScheduleCondition from './AttendScheduleCondition'

import { fireStore, getFunction } from './FirebaseConfig';
import { collection, getDocs, getDoc, doc, addDoc, setDoc, updateDoc, query, where, Timestamp } from 'firebase/firestore';

import '@aws-amplify/ui-react/styles.css';
import './reset.css'
import './reset2.css'
import './destyle.css'

import './AttendScheduleFilterModal.css'

export default function AttendScheduleFilterModal(props) {
    const { overrides, ...rest } = props;
    const [dontchange, setDontchane] = useState();
    const setShowModal = props.setshowmodal;
    const applyCondition = props.applycondition;
    const saveCondition = props.savecondition;

    const displaingDeptNames = {};
    const MIN_LEVEL = 2;
    const MAX_LEVEL = 4;
    const [currentLevel, setLevel] = useState(MIN_LEVEL);
    const [depts1, setDepts1] = useState([]);
    const [depts2, setDepts2] = useState([]);
    const [depts3, setDepts3] = useState([]);
    const [depts4, setDepts4] = useState([]);

    const [selecting, setSelecting] = useState();
    const [selected, setSelected] = useState(new Set());
    const [selectedComps, setSelectedComps] = useState();

    const [isLoading, setIsLoading] = useState(false);
    
    const getDept = async (level, name) => {
        level = parseInt(level)
        const func1 = getFunction('CallaGetDept');
        const deptSpec = "dept" + level
        var request;
        if (name == null) {
            request = {"operation": "getChild", "level": level};
        }
        else {
            request = {"operation": "getChild", "level": level, "name": name};
        }
        console.log(request);
        const response = await func1(request);
        const result = Array.from(response.data, (rec) => rec[deptSpec]);
        displaingDeptNames[level] = result;
        return result;
    };

    const resetClickedDept = async (clickedElement) => {
      for (var level in displaingDeptNames){
        for (var i=0; i < displaingDeptNames[level].length; i++){
          const id = "dept_" + level + "_" + i;
          const elm = document.getElementById(id);
          //console.log(elm);
          try{
            elm.className = "deptlist_normal";
          }
          catch {
            break;
          }
        }
      }
      if (clickedElement != null) {
        clickedElement.className = "deptlist_clicked";
      }
    };

    const onClickDept = async (elem) => {
      if (isLoading) return;
      setIsLoading(true);
      //console.log(elem);
      const id = elem.target.id;
      //console.log("id=" + id);
      const level = parseInt(id.split('_')[1]);
      const deptno = parseInt(id.split('_')[2]);
      //console.log("level=" + level);
      //console.log("deptno=" + deptno);
      const deptName = displaingDeptNames[level][deptno];
      setSelecting(deptName);
      //console.log("deptName=" + deptName);
      resetClickedDept(elem.target);

      if (level < MAX_LEVEL) {
        const newLevel = level + 1;
        const newRecords = await getDept(newLevel, deptName);
        if (newRecords.length >= 2){
          setLevel(newLevel);
          console.log("set level to" + newLevel);
          createDeptEntries(newLevel, newRecords);
        } else {
          setLevel(level);
          createDeptEntries(newLevel, []);
        }
      }
      setIsLoading(false);
    }

    const setLevelToParent = async () => {
      if (currentLevel <= MIN_LEVEL) return;

      createDeptEntries(currentLevel, []);
      setLevel(currentLevel - 1);
      console.log("set level to" + currentLevel + " to " + (currentLevel - 1));
    }

    const deleteDept = async (elem) => {
      const id = elem.target.id;
      //console.log("deleteDept id=" + id);
      const dept = id.split('_')[1];
      //console.log("deleteDept dept=" + dept);
      selected.delete(dept);
      setSelected(new Set(selected));
      renderSelectedDept()
    }

    const addDeptToSelected = async () => {
      if (selecting == null) return;
      selected.add(selecting);
      setSelected(new Set(selected));
      renderSelectedDept()
    }

    const renderSelectedDept = async (elem) => {
      console.log("renderSelectedDept()");
      //console.log(selected);
      const createSelectedDeptDOM = (dept) => {
        return <Flex
          gap="10px"
          direction="column"
          justifyContent="center"
          position="relative"
          padding="0px 0px 0px 0px"
          {...rest}
          {...getOverrideProps(overrides, "DepartmentSelectedRecord")}
        >
          <Flex
            gap="10px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "DepartmentSelectedList")}
          >
            <Flex
              id={"delete_" + dept}
              onClick={deleteDept}
              gap="0"
              width="fit-content"
              alignItems="center"
              shrink="0"
              height="24px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "SavedFilterConditionDelete")}
            >
              <Image
                width="24px"
                height="24px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                src="images/remove.png"
                style={{"pointerEvents": "none"}}
                {...getOverrideProps(overrides, "image 3")}
              ></Image>
            </Flex>
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              textTransform="capitalize"
              lineHeight="23.4375px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={dept}
              {...getOverrideProps(overrides, "DepartmentSelectedText")}
            ></Text>
          </Flex>
        </Flex>
        ;
      }
      const newElm = Array.from(selected, (dept) => createSelectedDeptDOM(dept));
      setSelectedComps(newElm);
    }

    const createDeptEntries = async (level, values) => {
        console.log(values);
        const records = []
        var i = 0;
        for (const name of values) {
            records.push(
              <Flex
                id={"dept_" + level + "_" + i}
                key={"dept_" + level + "_" + i}
                class="deptlist_normal"
                onClick={onClickDept}
                gap="10px"
                direction="column"
                justifyContent="center"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="5px 10px 5px 10px"
                {...getOverrideProps(overrides, "DepartmentSelectedList")}
                >
                <Text
                    style={{"pointerEvents": "none"}}
                    fontFamily="Inter"
                    fontSize="20px"
                    fontWeight="400"
                    color="rgba(0,0,0,1)"
                    textTransform="capitalize"
                    lineHeight="23.4375px"
                    textAlign="left"
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children={name}
                ></Text>
              </Flex>
            );
            i++;
        }
        const result = 
        <Flex
           gap="0px"
           direction="column"
           justifyContent="center"
           shrink="0"
           alignSelf="stretch"
           objectFit="cover"
           position="relative"
           padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "DepartmentSelectedList")}
            >
                {records}
            </Flex>
        ;

        switch(level) {
            case 1:
                setDepts1(result);
                break;
            case 2:
                setDepts2(result);
                break;
            case 3:
                setDepts3(result);
                break;
            case 4:
                setDepts4(result);
                break;
        }
    }

    const deleteAllSelected = async () => {
      setSelected(new Set());
      renderSelectedDept()
    }

    const performApplyCondition = async () => {
      const condition = new AttendScheduleCondition(selected);
      applyCondition(condition);
      hideModal();
    }

    const registerFilter = async () => {
      const condition = new AttendScheduleCondition(selected);
      saveCondition(condition)
      hideModal();
    }

    const hideModal = async () => {
      setShowModal(false);
    }

    const onStart = async () => {
        setIsLoading(true);
        const deptValues = await getDept(MIN_LEVEL, null);
        createDeptEntries(MIN_LEVEL, deptValues);
        setIsLoading(false);
    }

    useEffect(() => {
      // same as componentDidMount
      // https://qiita.com/nouka/items/69a74eebc43e7496007b
      onStart();
    }, [dontchange]);
    
    return (
        <Flex
          gap="0"
          direction="column"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...rest}
          {...getOverrideProps(overrides, "FilterModal")}
        >
          <Flex
            gap="0"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="10px 10px 10px 10px"
            backgroundColor="rgba(239,239,231,1)"
            {...getOverrideProps(overrides, "Frame 366")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              textTransform="capitalize"
              lineHeight="23.4375px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="表示するメンバーの絞り込み"
              {...getOverrideProps(
                overrides,
                "\u8868\u793A\u3059\u308B\u30E1\u30F3\u30D0\u30FC\u306E\u7D5E\u308A\u8FBC\u307F"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            shrink="0"
            position="relative"
            padding="10px 20px 10px 20px"
            {...getOverrideProps(overrides, "Frame 365")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              textTransform="capitalize"
              lineHeight="23.4375px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="組織"
              {...getOverrideProps(overrides, "\u7D44\u7E54")}
            ></Text>
            <Flex
              gap="0"
              width="fit-content"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 364")}
            >
              <Flex
                gap="10px"
                direction="column"
                shrink="0"
                height="308px"
                position="relative"
                padding="10px 10px 10px 10px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "DepartmentSelectComponent")}
              >
                <Flex
                  gap="10px"
                  width="fit-content"
                  alignItems="center"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "DepartmentSelect")}
                >
                  <Flex
                    gap="10px"
                    direction="row"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "DepartmentSelectBox")}
                  >
                    <p>{currentLevel}</p>
            
                    {currentLevel <= 2 && false &&
                      <ImplDepartmentSelect key="dept1" depts={depts1} isMaxLevel={currentLevel <= 1}> </ImplDepartmentSelect>}
                    {currentLevel <= 3 && currentLevel >= 2 && 
                      <ImplDepartmentSelect key="dept2" depts={depts2} isMaxLevel={currentLevel <= 2}> </ImplDepartmentSelect>}
                    {currentLevel <= 4 && currentLevel >= 3 && 
                      <ImplDepartmentSelect key="dept3" depts={depts3} isMaxLevel={currentLevel <= 3}> </ImplDepartmentSelect>}
                    {currentLevel <= 5 && currentLevel >= 4 &&
                      <ImplDepartmentSelect key="dept4" depts={depts4} isMaxLevel={currentLevel <= 4}> </ImplDepartmentSelect>}
                    {currentLevel <= 2 &&
                    <>
                      <Flex
                      gap="0"
                      width="fit-content"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(overrides, "rightarrow214601")}
                      >
                          <Image
                              width="36px"
                              height="36px"
                              shrink="0"
                              position="relative"
                              padding="0px 0px 0px 0px"
                              src="images/arrow_forward.png"
                              {...getOverrideProps(overrides, "rightarrow214602")}
                          ></Image>
                      </Flex>
                      <ImplDepartmentSelect key="dept_dummy" depts={["dummy"]} isMaxLevel={true}> </ImplDepartmentSelect>
                    </>
                    }
                  </Flex>
                </Flex>
                <Flex
                  gap="10px"
                  justifyContent="flex-end"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  objectFit="cover"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "DepartmentSelectButton")}
                >
                  {isLoading && 
                  <Text
                    fontFamily="Inter"
                    fontSize="20px"
                    fontWeight="400"
                    color="rgba(0,0,0,0.87)"
                    textTransform="capitalize"
                    lineHeight="23.4375px"
                    textAlign="left"
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 10px 0px"
                    whiteSpace="pre-wrap"
                    children="Loading..."
                    {...getOverrideProps(overrides, "Button214612")}
                  ></Text>
                  }
                  <Flex
                    gap="0"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    shrink="0"
                    height="44px"
                    position="relative"
                    border="1px SOLID rgba(0,0,0,0.54)"
                    borderRadius="10px"
                    padding="9px 9px 9px 9px"
                    {...getOverrideProps(overrides, "DepartmentSelectParentButton")}
                  >
                    <Text
                      onClick={setLevelToParent}
                      fontFamily="Inter"
                      fontSize="20px"
                      fontWeight="400"
                      color="rgba(0,0,0,0.87)"
                      textTransform="capitalize"
                      lineHeight="23.4375px"
                      textAlign="left"
                      display="flex"
                      direction="column"
                      justifyContent="flex-start"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="一つ上へ"
                      {...getOverrideProps(overrides, "Button214610")}
                    ></Text>
                  </Flex>
                  <Flex
                    onClick={addDeptToSelected}
                    gap="0"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    shrink="0"
                    height="44px"
                    position="relative"
                    border="1px SOLID rgba(0,0,0,0.54)"
                    borderRadius="10px"
                    padding="9px 9px 9px 9px"
                    {...getOverrideProps(overrides, "DepartmentSelectAddButton")}
                  >
                    <Text
                      fontFamily="Inter"
                      fontSize="20px"
                      fontWeight="400"
                      color="rgba(0,0,0,0.87)"
                      textTransform="capitalize"
                      lineHeight="23.4375px"
                      textAlign="left"
                      display="flex"
                      direction="column"
                      justifyContent="flex-start"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="追加"
                      {...getOverrideProps(overrides, "Button214612")}
                    ></Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              textTransform="capitalize"
              lineHeight="23.4375px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="選択済み条件（or）"
              {...getOverrideProps(overrides, "")}
            ></Text>
            <Flex
              gap="10px"
              direction="row"
              minHeight="100px"
              justifyContent="flex-end"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "")}
            >
              {selectedComps}
            </Flex>
            <Flex
              gap="10px"
              justifyContent="flex-end"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "FilterDialogFinalList")}
            >
              <Flex
                onClick={deleteAllSelected}
                gap="0"
                direction="column"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                height="44px"
                position="relative"
                border="1px SOLID rgba(0,0,0,0.54)"
                borderRadius="10px"
                padding="9px 9px 9px 9px"
                {...getOverrideProps(overrides, "FilterClearButton")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(0,0,0,0.87)"
                  textTransform="capitalize"
                  lineHeight="23.4375px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="クリア"
                  {...getOverrideProps(overrides, "Button214583")}
                ></Text>
              </Flex>
              {false && 
              <Flex
                onClick={registerFilter}
                gap="0"
                direction="column"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                height="44px"
                position="relative"
                border="1px SOLID rgba(0,140,140,1)"
                borderRadius="10px"
                padding="9px 9px 9px 9px"
                {...getOverrideProps(overrides, "FilterRegisterButton")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(0,0,0,0.87)"
                  textTransform="capitalize"
                  lineHeight="23.4375px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="条件登録"
                  {...getOverrideProps(overrides, "Button214585")}
                ></Text>
              </Flex>
              }
              <Flex
                onClick={hideModal}
                gap="0"
                direction="column"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                height="44px"
                position="relative"
                border="1px SOLID rgba(0,0,0,0.54)"
                borderRadius="10px"
                padding="9px 9px 9px 9px"
                {...getOverrideProps(overrides, "FilterModalCloseButton")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(0,0,0,0.87)"
                  textTransform="capitalize"
                  lineHeight="23.4375px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="閉じる"
                  {...getOverrideProps(overrides, "Button214587")}
                ></Text>
              </Flex>
              <Flex
                onClick={performApplyCondition}
                gap="0"
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                shrink="0"
                height="44px"
                position="relative"
                border="1px SOLID rgba(140,0,140,1)"
                borderRadius="10px"
                padding="9px 9px 9px 9px"
                {...getOverrideProps(overrides, "FilterConfirmButton")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(0,0,0,0.87)"
                  textTransform="capitalize"
                  lineHeight="23.4375px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="　実行　"
                  {...getOverrideProps(overrides, "Button214589")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
    );
  }
  