import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signInWithRedirect
} from "firebase/auth";
import { auth, provider } from "./FirebaseConfig.js";
/* 「Link」をimport↓ */
import { Navigate, Link } from "react-router-dom";

import { Flex, View, ScrollView, Text } from "@aws-amplify/ui-react";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import LoginButton from "./ui-components/LoginButton";

import '@aws-amplify/ui-react/styles.css';
import './reset.css'
import './reset2.css'
import './destyle.css'

import LoadingComponent from "./Loading";

const Login = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signInWithRedirect(auth, provider);
    } catch(error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
  });

  return (
    <>
      {!loading ? (
        <>
        {user ? (
            <Navigate to={`/`} />
        ) : (
            <>
                <AmplifyProvider>
                    <Flex
                        display="flex"
                        gap="0"
                        direction="column"
                        width="100%"
                        height="100%"
                        margin="auto"
                        alignItems="center"
                        position="absolute"
                        padding="0px 0px 0px 0px"
                        backgroundColor="rgba(0, 0, 0 ,0.03)" 
                    >
                    <a
                        style={{
                            width: "80%"
                            ,height: "80%"
                            ,margin: "auto"
                        }}
                        href=""
                    >
                        <View
                        display="flex"
                        gap="0"
                        width="100%"
                        height="100%"
                        direction="column"
                        alignItems="center"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        backgroundColor="rgba(255,255,255,1)"
                        border="1px SOLID rgba(0, 0, 0 ,0.3)"
                        onClick={handleSubmit}
                        >
                            <LoginButton margin="auto"/>
                        </View>
                    </a>
                    </Flex>
                </AmplifyProvider>
            </>
        )}
        </>
      ) : (
        <>
          <AmplifyProvider>
              <LoadingComponent
                fontStyle="bold"
                fontSize="64px"
                text="Now Loading..."
              />
          </AmplifyProvider>
        </>
      )}
    </>
  );

};

export default Login;