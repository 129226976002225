
const hostName = window.location.host;

export const ENV_LOCAL = 0;
export const ENV_DEV = 1;
export const ENV_PRD = 2;

export const ENV = hostName == 'calla.valuesccg.net' ? ENV_PRD : hostName === 'calla-dev.valuesccg.net' ? ENV_DEV : ENV_LOCAL;

export const sysout = (s) => {
    if (ENV === ENV_PRD) return;
    console.log(s);
};