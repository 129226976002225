

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./FirebaseConfig.js";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, View, ScrollView, Text, Icon, Image } from "@aws-amplify/ui-react";
import { AmplifyProvider } from "@aws-amplify/ui-react";

import SavedFilterConditionRecord from "./ui-components/SavedFilterConditionRecord";
import SavedFilterList from "./ui-components/SavedFilterList";
import DepartmentSelectedRecord from "./ui-components/DepartmentSelectedRecord";
import DepartmentSelectComponent from "./ui-components/DepartmentSelectComponent";

import { fireStore, getFunction } from './FirebaseConfig';
import { collection, getDocs, getDoc, doc, addDoc, setDoc, updateDoc, query, where, Timestamp } from 'firebase/firestore';

import '@aws-amplify/ui-react/styles.css';
import './reset.css'
import './reset2.css'
import './destyle.css'

import './ImplDepartmentSelect.css'

export default function ImplDepartmentSelect(props) {
    const { overrides, ...rest } = props;
    const depts = props.depts;
    const isMaxLevel = props.isMaxLevel;
    
    return (
        <>
            <Flex
            gap="0"
            direction="column"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 367")}
            >
                <ScrollView
                gap="10px"
                direction="column"
                width="300px"
                height="200px"
                shrink="0"
                position="relative"
                border="1px SOLID rgba(0,0,0,0.54)"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "DepartmentSelectList")}
                >
                    {depts}
                </ScrollView>
            </Flex>
            
            {!isMaxLevel ? (
                <Flex
                gap="0"
                width="fit-content"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "rightarrow214601")}
                >
                    <Image
                        width="36px"
                        height="36px"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        src="images/arrow_forward.png"
                        {...getOverrideProps(overrides, "rightarrow214602")}
                    ></Image>
                </Flex>
            ) : (
                <></>
            )}
      </>
    );
  }
  
