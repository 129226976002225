
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./FirebaseConfig.js";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, View, ScrollView, Text, Icon, Image } from "@aws-amplify/ui-react";
import { AmplifyProvider } from "@aws-amplify/ui-react";

import SavedFilterConditionRecord from "./ui-components/SavedFilterConditionRecord";
import SavedFilterList from "./ui-components/SavedFilterList";
import DepartmentSelectedRecord from "./ui-components/DepartmentSelectedRecord";
import DepartmentSelectComponent from "./ui-components/DepartmentSelectComponent";
import ImplDepartmentSelect from './ImplDepartmentSelect';
import AttendScheduleCondition from './AttendScheduleCondition'

import { fireStore, getFunction } from './FirebaseConfig';
import { collection, getDocs, getDoc, doc, addDoc, setDoc, updateDoc, query, where, Timestamp } from 'firebase/firestore';

import { sysout } from './global.js'
import { getYMDjapanese } from './util.js';

import '@aws-amplify/ui-react/styles.css';
import './reset.css'
import './reset2.css'
import './destyle.css'

export default function AttendMembersModal(props) {
  sysout(props);

    const hideModal = async () => {
      props.setShowModal(0);
    }

    const onStart = async () => {
    }

    useEffect(() => {
      // same as componentDidMount
      // https://qiita.com/nouka/items/69a74eebc43e7496007b
      onStart();
    }, []);
    
    return (
        <Flex
          gap="0"
          direction="column"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
        >
          <Flex
            gap="0"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="10px 10px 10px 10px"
            backgroundColor="rgba(239,239,231,1)"
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              textTransform="capitalize"
              lineHeight="23.4375px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={getYMDjapanese(props.data.date) + " の出社メンバー一覧"}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            shrink="0"
            position="relative"
            padding="10px 20px 10px 20px"
          >
            <Flex
              gap="20px"
              direction="column"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="10px 10px 10px 10px"
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                textTransform="capitalize"
                lineHeight="23.4375px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={"絞込条件：" + (props.appliedCondition.depts.length === 0 ? 'なし（全社）' : props.appliedCondition.depts.join('、'))}
              ></Text>
              <ScrollView
              display="flex"
              gap="0px"
              direction="column"
              width="70vw"
              minHeight="30vw"
              maxHeight="30vw"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  textTransform="capitalize"
                  lineHeight="23.4375px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={props.data.attendList}
                ></Text>
              </ScrollView>
            </Flex>
          </Flex>
        </Flex>
    );
  }
  