
import { Flex, View, ScrollView, Text } from "@aws-amplify/ui-react";

export default function LoadingComponent(props) {
    return (
      <Flex
          display="flex"
          gap="0"
          direction="column"
          width="100%"
          height="100%"
          margin="auto"
          alignItems="center"
          position="absolute"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255, 255, 255 ,1)" 
          style={{cursor: "wait"}}
      >
        <Flex
        gap="10px"
        width="100%"
        height="100%"
        direction="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
        padding="10px 10px 10px 10px"
      >
          <Text
            fontFamily="Inter"
            fontStyle={props.fontStyle}
            fontSize={props.fontSize}
            fontWeight="400"
            color="rgba(0,0,0,0.3)"
            textTransform="capitalize"
            lineHeight="23.4375px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={props.text} />
        </Flex>
      </Flex>
    );
}