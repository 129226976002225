import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./FirebaseConfig.js";
import Page from "./Page";

const Mypage = () => {
  const [user, setUser] = useState("");

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const logout = async () => {
    await signOut(auth);
    navigate("/login/");
  }

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      var isValidUser = false;
      if (currentUser) {
        if (currentUser.email.endsWith("@valuesccg.com")) {
          // 外部ユーザを弾く実装をしていますがフロントエンドなので意味なし
          // Firebase Authentication側で要対応。
          // -> 「OAuth同意画面」の「ユーザの種類」を「内部」に設定
          isValidUser = true;
          setLoading(false);
        }
      }
      if (!isValidUser) logout();
    });
  }, []);

  return (
    <>
      {!loading && (
        <>
          {!user ? (
            <Navigate to={`/login/`} />
          ) : (
            <>
              <Page user={user} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Mypage;