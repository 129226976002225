import React, { useState, useEffect } from "react";
import {
  Navigate
} from "react-router-dom";

const NoPageFound = () => {
  return (
    <Navigate to={`/`} />
  )
};

export default NoPageFound;