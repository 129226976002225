// app.tsx
import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import useIntersection from './intersection';
import AttendScheduleRecord from "./ui-components/AttendScheduleRecord";
import { Flex, View, ScrollView, Text } from "@aws-amplify/ui-react";
import { useBreakpointValue } from '@aws-amplify/ui-react'

import { fireStore, getFunction } from './FirebaseConfig';
import { collection, getDocs, getDoc, doc, addDoc, updateDoc, query, where, Timestamp } from 'firebase/firestore';

import { getYM, getYMD, getYMDhyphen, getWeekday, date_trunc, getDateFromYMD
  , endOfMonth, endOfNextMonth, startOfMonth, startOfNextMonth
  , getDatesOfMonth, getDates
} from './util'
import { sysout } from './global.js'
import { auth } from './FirebaseConfig'
import LoadingComponent from "./Loading";

import './InfiniteScroll2.css'

export default function DateBarComponent(props) {
  //sysout(props)

  const [statButtonHome, setStatButtonHome] = useState(0);
  const [statButtonOffice, setStatButtonOffice] = useState(0);
  const [documentId, setDocumentId] = useState(props.documentId);

  const currentUserName = auth.currentUser.email;

  //sysout("go")

  const writeData = async (ymd, location_kbn) => {
    const yyyymm = ymd.slice(0, 6);
    if (documentId != null && documentId != undefined && documentId != 'undefined') {
      const docRef = doc(fireStore, 'calla/planWorkLocation/' + yyyymm + '/' + currentUserName + '/t_location_plan/' + documentId);
      const locRef = await updateDoc(docRef, {
        "location_kbn": location_kbn
      });
    }
    else {
      const cref = collection(fireStore, 'calla', 'planWorkLocation', yyyymm, currentUserName, 't_location_plan');
      const locRef = await addDoc(cref, {
        "location_kbn": location_kbn
        ,"work_date": Timestamp.fromDate(getDateFromYMD(ymd))
      });
      setDocumentId(locRef.id);
    }
  }

  const onLocationDetermined  = useCallback(async (isHome) => {
    let statHome = statButtonHome;
    let statOffice = statButtonOffice;
    if (statHome < 0 || statOffice < 0) return;
    setStatButtonHome(-1);
    setStatButtonOffice(-1);
    const ymd = getYMD(props.date);
    let location_kbn;
    if (isHome) {
        if (statHome == 1) location_kbn = 0;
        if (statHome == 0) location_kbn = 1;
        await writeData(ymd, location_kbn);
    }
    else {
        if (statOffice == 1) location_kbn = 0;
        if (statOffice == 0) location_kbn = 2;
        await writeData(ymd, location_kbn);
    }
    updateButtonState(location_kbn);
  });

  const updateButtonState = useCallback(async (location_kbn) => {
    //sysout("updateButtonState() location_kbn = ", location_kbn)
    if (location_kbn == 0) {
        setStatButtonHome(0);
        setStatButtonOffice(0);
    }
    if (location_kbn == 1) {
        setStatButtonHome(1);
        setStatButtonOffice(0);
    }
    if (location_kbn == 2) {
        setStatButtonHome(0);
        setStatButtonOffice(1);
    }
  },[]);

  const onStart = async () => {
    updateButtonState(props.location_kbn);
  }

  useEffect(() => {
    // same as componentDidMount
    // https://qiita.com/nouka/items/69a74eebc43e7496007b
    onStart();
  }, []);
  
  return (
    <>
        <Flex
        id={"record_"+getYMD(props.date)}
        className={props.weekdayClass}
        gap="10px"
        direction="row"
        width="1346px"
        alignItems="center"
        position="relative"
        padding="3px"
        >
        <Flex
            gap="10px"
            direction="row"
            width="fit-content"
            alignItems="center"
            shrink="0"
            height="24px"
            position="relative"
            padding="0px 10px 0px 10px"
        >
            <Text
            id={"ShorcutNumber_" + getYMD(props.date)}
            minWidth="24px"
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(0,0,0,0.87)"
            textTransform="capitalize"
            lineHeight="23.4375px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children=""
            ></Text>
        </Flex>
        <Flex
            gap="10px"
            direction="row"
            width="fit-content"
            alignItems="flex-start"
            shrink="0"
            height="44px"
            position="relative"
            padding="10px 10px 10px 10px"
        >
            <Text
            minWidth="120px"
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(0,0,0,0.87)"
            textTransform="capitalize"
            lineHeight="23.4375px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={(props.date.getMonth()+1) + '/' + props.date.getDate() + '（' + getWeekday(props.date) + '）'}
            ></Text>
        </Flex>
        <Flex
            gap="10px"
            direction="row"
            width="fit-content"
            alignItems="center"
            shrink="0"
            height="44px"
            position="relative"
            padding="0px 0px 0px 0px"
        >
            <Flex
            id={"buttonAtHome_" + getYMD(props.date)}
            className={statButtonHome == 0 ? "button_info_default" : statButtonHome == 1 ? 'button_info_reversed' : 'button_info_disabled'}
            onClick={(isHome) => onLocationDetermined(true)}
            gap="0"
            direction="column"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="44px"
            position="relative"
            borderRadius="10px"
            >
            <Text
                fontFamily="Inter"
                fontSize="20px"
                textTransform="capitalize"
                lineHeight="23.4375px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="自宅"
            ></Text>
            </Flex>
            <Flex
            id={"buttonAtOffice_" + getYMD(props.date)}
            className={statButtonOffice == 0 ? "button_error_default" : statButtonOffice == 1 ? 'button_error_reversed' : 'button_error_disabled'}
            onClick={(isHome) => onLocationDetermined(false)}
            gap="0"
            direction="column"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="44px"
            position="relative"
            borderRadius="10px"
            >
            <Text
                fontFamily="Inter"
                fontSize="20px"
                textTransform="capitalize"
                lineHeight="23.4375px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="オフィス"
            ></Text>
            </Flex>
        </Flex>
        <Flex
            onClick={() => props.showAttendMemberModal(props.date, props.attendList)}
            style={{
              cursor: 'pointer'
            }}
            gap="10px"
            direction="row"
            width="100%"
            alignItems="flex-start"
            shrink="0"
            height="44px"
            position="relative"
            padding="10px 20px 10px 20px"
        >
            <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="400"
            color={props.attendList == null ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.87)"}
            textTransform="capitalize"
            lineHeight="23.4375px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={props.attendList == null ? "loading.." : props.attendList}
            ></Text>
        </Flex>
      </Flex>
    </>
  )
}